var reInitGCaptcha;

var reInitBookingForm;

(function ($) {
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LeOZnQUAAAAAP5gCorueYqpmapAvXd2jA8sLxHS").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LeOZnQUAAAAAP5gCorueYqpmapAvXd2jA8sLxHS', {action: 'form'})
                    .then(function (token) {
                        $('[name="gtoken"]').val(token);
                    });
            });
        });
    };


    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    reInitBookingForm = function($fn) {
        let $lang = $("html").attr("lang"),
            $form = $fn.find(".part_form_contact_booking");
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
                let $dp_alt = $form.find("[data-dp-alt=\"date\"]"),
                    $dp2_alt = $form.find("[data-dp-alt=\"date_alt\"]"),
                    $today = new Date();

                let $dp_options = {
                    language: $lang,
                    dateFormat: "d/m/yyyy",
                    altFieldDateFormat: "yyyy-mm-dd",
                    position: "bottom left",
                    autoClose: true,
                    range: true,
                    multipleDatesSeparator: " - ",
                    minDate: $today,
                    onHide: function(inst){
                        inst.update('position', 'bottom left'); // Update the position to the default again
                    },
                    onShow: function(inst, animationComplete){
                        // Just before showing the datepicker
                        if(!animationComplete){
                            let iFits = false;
                            // Loop through a few possible position and see which one fits
                            $.each(['bottom left', 'top left', 'top right', 'bottom right'], function (i, pos) {
                                if (!iFits) {
                                    inst.update('position', pos);
                                    let fits = isElementInViewport(inst.$datepicker[0]);
                                    if (fits.all) {
                                        iFits = true;
                                    }
                                }
                            });
                        }
                    }
                };

                let $dp = $form.find("[data-dp=\"date\"]").datepicker($.extend({},$dp_options,{
                    altField: $dp_alt,
                })).data('datepicker');

                let $dp2 = $form.find("[data-dp=\"date_alt\"]").datepicker($.extend({},$dp_options,{
                    altField: $dp2_alt,
                })).data('datepicker');
            });
    };

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function (e) {
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function () {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function () {
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    //gallery
    $(document).on('click', '[data-gallery-change]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1},
            dataType: "json",
        }).done(function (data) {
            $('[data-gallery-change]').removeClass('mod--active');
            $el.addClass('mod--active');
            // $el.closest(".comp_gallery").anchor_anim(500);
            fn_ajaxHandler(data);
            sr.sync();

            let lg = $("[data-lib-lightgallery]");
            if(lg.length && lg.find(".lib--lightgallery-item").length) {
                $.getScript(cdnjs.lightgallery).done(()=>{
                    $.getScript(cdnjs.froogaloop).done(()=>{
                        lg.lightGallery({
                            thumbnail: true,
                            selector: ".lib--lightgallery-item",
                            exThumbImage: 'data-exthumbimage',
                            fullScreen: false,
                            zoom: true,
                            actualSize: false,
                            hash: false,
                            download: true,
                            autoplay: false,
                            autoplayControls: false,
                            videoMaxWidth: '1024px'
                        });
                    });
                });
            }
        });
    });

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('click', '[data-section]', function (e) {
        e.preventDefault();
        $('[data-section]').removeClass('mod--active');
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date, id: $el.data('section'), do: 'changeSection'},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                $el.addClass('mod--active');
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
                formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();

        setTimeout(function() {
            $('.mod--error').hide();
        },5500);

        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }

        let frm = $(this),
                formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }
                    if ($('.comp_booking_form').length) {
                        reInitBookingForm($('.comp_booking_form'));
                    }

                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }

                    setTimeout(function() {
                        $('.mod--success').hide();
                    },4000);
                });
            }
        });
    });

    doc.on('click', 'a[data-component-ajax]', function (e) {
        e.preventDefault();
        var url = '';
        url = $(this).attr('data-href');
        if (!url || !url.length) {
            url = $(this).attr('href');
        }

        if (!url || !url.length) {
            console.error('no href attribute for ajax component link');
            return;
        }


        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
            initCalendar();
        });
    });

    doc.on("change", "select[data-ajax-select]", function (e) {
        let url = $(this).val();

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
            initCalendar();
        });
    });

    // go booking
    $(document).on('click', '[data-send-booking]', function (e) {
        e.preventDefault();
        let val = $(this).closest('.wrp_comp_body').find('[data-package]').val();
        if (val) {
            window.open(val, '_blank');
        }
    });

    let picture = document.createElement('picture'),
            strObj = '' + picture;
    if (strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
        html.addClass("safari");
    }

    const masonry = $("[data-masonry-col]");

    if (masonry.length) {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
            $.getScript(cdnjs.masonry).done(function () {
                cssLoaded(function () {
                    $("[data-masonry-col]").each(function () {
                        let elm = $(this);
                        elm.masonry({
                            itemSelector: '.col--masonry',
                            columnWidth: elm.data("masonry-col"),
                            percentPosition: true
                        });
                    });
                })
            });
        }
    }

    let lg = $("[data-lib-lightgallery]");

    if(lg.length && lg.find(".lib--lightgallery-item").length) {
        $.getScript(cdnjs.lightgallery).done(()=>{
            lg.lightGallery({
                thumbnail: true,
                selector: ".lib--lightgallery-item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false
            });
        });
    }


    if ($(".c-cookieconsent").length || $('.c-form-cookieconsent').length) {
        $.getScript(cdnjs.cookieconsent);

        if (localStorage.getItem('lib-cookieconsent')) {
            let cookieConsent = JSON.parse(localStorage.getItem('lib-cookieconsent'));

            cookieConsent.push('necessary');

            cookieConsent = cookieConsent.map(function (i) {
                if (i === 'performance') {
                    return 'analytics'
                } else {
                    return i
                }
            });

            window.beAcceptedCookiePolicies = cookieConsent

            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event':'cookieConsent', 'cookieConsentCategory': cookieConsent});
        }

        doc.on('click', '[data-lib-cookieconsent-approve]', function() {
            window.beAcceptedCookiePolicies = ["necessary", "marketing", "analytics"];
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event':'cookieConsent','cookieConsentCategory': ["necessary", "marketing", "analytics"]});
        })

        doc.on('click', '[data-lib-cookieconsent-decline]', function() {
            window.beAcceptedCookiePolicies = ["necessary"];
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({'event':'cookieConsent','cookieConsentCategory': ["necessary"]});
        })

    }

    $('[data-packeges]').click(function(e){
        e.preventDefault();
        $('[data-bookolo]').append('<input type="hidden" name="preferpackage" value="all" />');
        $('[data-book]').trigger("click");
    });

    if ($("#layout_main").children().length <= 1) {
        $('#layout_arrow').hide();
    }

    $('#layout_arrow').click(function(e){
        let goal = html.scrollTop();
        let counts = [];

        if (!$(this).hasClass("state--back")) {
            $("#layout_main").children().each(function() {
                counts.push($(this).position().top);
            });

            var closest = counts.reduce(function(prev, curr) {
                return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
            });

            $('html').animate({
                scrollTop: counts[counts.indexOf(closest)+1] - 96
            }, 500);
        } else {
            $('html').animate({
                scrollTop: 0
            }, 500);
        }
    });

    win.on("scroll", function(){
        if ($("#layout_main").children().last().position().top - $("#layout_main").children().outerHeight() < html.scrollTop()) {
            $('#layout_arrow').addClass("state--back");
        } else {
            $('#layout_arrow').removeClass("state--back");
        }
    })

    if ($(".part_profitroom").length) {
        let profitroom_lang = (lang === "cs") ? "cz" : lang;

        $.getScript("https://wis.upperbooking.com/resortsvatakaterina/be-panel?locale="+profitroom_lang).done(function () {});
    }

})(jQuery);
