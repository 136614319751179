(function ($) {
    let $fn = $(".comp_block_images");

    if ($fn.length) {
        $.getScript(cdnjs.lightgallery).done(()=>{
            $fn.lightGallery({
                thumbnail: true,
                selector: ".elm_gallery_item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false
            });
        });
    }
})(jQuery);