 function initCalendar() {
        var $fn = $(".comp_calendar");

        if ($fn.length) {
            let $form = $fn.find(".wrp_comp_header").find("form"),
                $lang = html.attr("lang");

            var $dp_options;

            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
                $dp_options = {
                    language: $lang,
                    dateFormat: "dd/mm/yyyy",
                    altFieldDateFormat: "yyyy-mm-dd",
                    position: "bottom left",
                    autoClose: true,
                    onHide: function(inst){
                        inst.update('position', 'bottom left'); // Update the position to the default again
                    },
                    onShow: function(inst, animationComplete){
                        // Just before showing the datepicker
                        if(!animationComplete){
                            let iFits = false;
                            // Loop through a few possible position and see which one fits
                            $.each(['bottom left', 'top left', 'top right', 'bottom right'], function (i, pos) {
                                if (!iFits) {
                                    inst.update('position', pos);
                                    let fits = isElementInViewport(inst.$datepicker[0]);
                                    if (fits.all) {
                                        iFits = true;
                                    }
                                }
                            });
                        }
                    },
                };

                    var $form = $fn.find(".wrp_comp_header").find("form");
                    var $dp_in_alt = $form.find("[data-dp-alt=\"from\"]"),
                        $dp_out_alt = $form.find("[data-dp-alt=\"to\"]"),
                        $today = new Date(),
                        indate = $today,
                        outdate = new Date($today.getTime() + (24*60*60*1000));

                    if($dp_in_alt.val() !== "") {
                        indate = new Date($dp_in_alt.val());
                        outdate =  new Date(indate.getTime() + (24*60*60*1000));
                    }

                    var $dp_in = $form.find("[data-dp=\"from\"]").datepicker($.extend({},$dp_options,{
                        altField: $dp_in_alt,
                        minDate: $today,
                        onSelect:function ($fd,$d,$inst) {
                            setTimeout(function () {
                                if($d > new Date($dp_out_alt.val())){
                                    $dp_out.selectDate(new Date($d.getTime() + (24*60*60*1000)))
                                }
                                else {
                                    $.ajax({
                                        url: $inst.$el.attr('data-ajax-link'),
                                        method: 'post',
                                        data: {dateFrom: $dp_in_alt.val(), dateTo: $dp_out_alt.val()},
                                        dataType: 'json'
                                    }).done(function (payload) {
                                       fn_ajaxHandler(payload, function() {
                                         initCalendar();
                                       });
                                    });
                                }
                            },50);
                        }
                    })).data('datepicker');

                    var $dp_out = $form.find("[data-dp=\"to\"]").datepicker($.extend({},$dp_options,{
                        altField: $dp_out_alt,
                        minDate: outdate,
                        onSelect: function ($fd,$d,$inst){
                            setTimeout(function () {
                                $.ajax({
                                    url: $inst.$el.attr('data-ajax-link'),
                                    method: 'post',
                                    data: {dateFrom: $dp_in_alt.val(), dateTo: $dp_out_alt.val()},
                                    dataType: 'json'
                                }).done(function (payload) {
                                    fn_ajaxHandler(payload, function() {
                                        initCalendar();
                                    });
                                });
                            },50)

                        }
                    })).data('datepicker');
                

                $form.on("click",".elm_icon",function () {
                    $(this).closest(".mod--row").find("[data-dp]").focus();
                })
            });
        }
    }