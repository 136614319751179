(function($) {
    let $fn = $("#layout_header"),
        $nav = $("#layout_nav"),
        $booking = $("#layout_booking"),
        $nav_ssm = $("#layout_nav_ssm"),
        firstShow = true;

    if ($fn.length) {
        $.getScript(cdnjs.touchswipe).done(function(){
            $('.ssm-nav').slideAndSwipe();
        });
        let offset = 5;
        let visual = doc.find(".comp_visual, .comp_visual_button, .comp_visual_text, .comp_visual_image").first();
        let headroom;

        if(visual.length) {
            let logo = visual.find(".wrp_comp_logo");
            if(logo.length){
                offset = visual.position().top + logo.position().top + logo.outerHeight();
            }

            win.on("resize",function () {
                offset = visual.position().top + logo.position().top + logo.outerHeight();
                headroom.offset = offset;
            });
        }

        headroom = new Headroom($fn[0], {
            offset: offset,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();

        function noScroll(){
            if(!html.hasClass("mod--no-scroll")){
                // if ($(document).height() > $(window).height()) {
                //     let scrollTop = (html.scrollTop()) ? html.scrollTop() : body.scrollTop();
                //     html.addClass("mod--no-scroll").css('top',-scrollTop);
                // }
                html.addClass("mod--no-scroll");
            }
            else {
                if($nav.hasClass("mod--active") || $booking.hasClass("mod--active")){
                    return;
                }
                // let scrollTop = parseInt(html.css('top'));
                // html.removeClass("mod--no-scroll").scrollTop(-scrollTop);
                // body.scrollTop(-scrollTop);

                html.removeClass("mod--no-scroll");
            }
            $fn.toggleClass("headroom--not-top-fake",($nav.hasClass("mod--active") || $booking.hasClass("mod--active")));
        }

        doc.on("click",function (e) {
            if($(e.target).is(".elm_content") && ($nav.hasClass("mod--active") || $booking.hasClass("mod--active"))){
                $booking.removeClass("mod--active");
                $nav.removeClass("mod--active");
                html.removeClass("mod--no-scroll");
            }
        });

        $fn.on("click","[data-menu-toggle]",function () {
            $nav.find(".wrp_menu_section.mod--active").removeClass("mod--active");
            $nav.find(".mod--selected").removeClass("mod--selected");
            $booking.removeClass("mod--active");
            $nav.toggleClass("mod--active");
            noScroll();
        });

        $fn.on("click","[data-booking-toggle]",function () {
            $nav.find(".wrp_menu_section.mod--active").removeClass("mod--active");
            $nav.find(".mod--selected").removeClass("mod--selected");
            $nav.removeClass("mod--active");
            $booking.toggleClass("mod--active");
            noScroll();
        });

        win.on("resize",function () {
           if(win.width() < 1200) {
               $nav.find(".wrp_menu_section.mod--active").removeClass("mod--active");
               $nav.find(".mod--selected").removeClass("mod--selected");
               $booking.removeClass("mod--active");
               $nav.removeClass("mod--active");
               html.removeClass("mod--no-scroll");
           }
        });

        let $secondLevels = $nav.find("[data-menu-id]"),
            $thirdLevels = $nav.find("[data-submenu-id]");

        //2nd level submenus

        $nav.on("mouseenter","[data-menu]",function (e) {
            let $el = $(this),
                id = $el.data("menu"),
                $target = $secondLevels.filter("[data-menu-id=\""+id+"\"]");

            $secondLevels.filter(".mod--active").removeClass("mod--active");
            $thirdLevels.filter(".mod--active").not("[data-submenu-id=\""+$el.data("submenu")+"\"]").removeClass("mod--active");
            $nav.find("[data-menu]").removeClass("mod--selected");
            $secondLevels.find(".mod--selected").removeClass("mod--selected");

            if($target.length){
                $target.addClass("mod--active");
                if(!$el.hasClass("mod--selected")) {
                    $el.parents(".wrp_menu_section").find(".mod--selected").removeClass("mod--selected");
                    $el.addClass("mod--selected");
                    $thirdLevels.filter(".mod--active").removeClass("mod--active");
                    return;
                }
            }

            $nav.find("[data-menu]").filter("mod--selected").removeClass("mod--selected");
        });

        //3rd level submenus

        $nav.on("mouseenter","[data-submenu]",function (e) {
            let $el = $(this),
                id = $el.data("submenu"),
                $target = $thirdLevels.filter("[data-submenu-id=\""+id+"\"]");

            $thirdLevels.filter(".mod--active").removeClass("mod--active");
            $nav.find("[data-submenu]").removeClass("mod--selected");

            if($target.length){
                $target.addClass("mod--active");
                if(!$el.hasClass("mod--selected")) {
                    $el.parents(".wrp_menu_section").find(".mod--selected").removeClass("mod--selected");
                    $el.addClass("mod--selected");
                    return;
                }
            }

            $nav.find("[data-submenu]").filter("mod--selected").removeClass("mod--selected");
        });

        //booking

        let $lang = $("html").attr("lang"),
            $form = $booking.find("form");

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
            let $dp_in_alt = $form.find("[data-dp-alt=\"arrival\"]"),
                $dp_out_alt = $form.find("[data-dp-alt=\"departure\"]"),
                $today = new Date();

            let $dp_options = {
                language: $lang,
                dateFormat: "d/m/yyyy",
                altFieldDateFormat: "yyyy-mm-dd",
                position: "bottom left",
                autoClose: true,
                onHide: function(inst){
                    inst.update('position', 'bottom left'); // Update the position to the default again
                },
                onShow: function(inst, animationComplete){
                    // Just before showing the datepicker
                    if(!animationComplete){
                        let iFits = false;
                        // Loop through a few possible position and see which one fits
                        $.each(['bottom left', 'top left', 'top right', 'bottom right'], function (i, pos) {
                            if (!iFits) {
                                inst.update('position', pos);
                                let fits = isElementInViewport(inst.$datepicker[0]);
                                if (fits.all) {
                                    iFits = true;
                                }
                            }
                        });
                    }
                }
            };

            let $dp_in = $form.find("[data-dp=\"arrival\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_in_alt,
                minDate: $today,
                onSelect:function ($fd,$d,$inst) {
                    $dp_out.update({
                        minDate: new Date($d.getTime() + (24*60*60*1000))
                    });
                    setTimeout(function () {
                        if($d > new Date($dp_out['$el'].siblings("input").val())){
                            $dp_out.selectDate(new Date($d.getTime() + (24*60*60*1000)))
                        }
                    },50);
                }
            })).data('datepicker');

            let $dp_out = $form.find("[data-dp=\"departure\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_out_alt,
                minDate: new Date($today.getTime() + (24*60*60*1000)),
                startDate: new Date($today.getTime() + (24*60*60*1000))
            })).data('datepicker');

            /*Prefill dates*/

            $dp_in.selectDate($today);
            $dp_out.selectDate(new Date($today.getTime() + (24*60*60*1000)))

        });

        //show search

        $fn.on('click',"[data-search]",function (e) {
            let $wrap = $(this).closest(".part_ui_input"),
                $input = $wrap.find("input");
            if(!$wrap.hasClass("mod--active")){
                e.preventDefault();
                e.stopPropagation();
                $input.focus();
                $wrap.addClass("mod--active");
            }
            $input.on("blur",function () {
                setTimeout(function () {
                    $wrap.removeClass("mod--active");
                },500);
            })
        });

        //SSM nav

        if ($nav_ssm.length) {
            let $animation_delay = 60,
                $animation_duration = 600;

            $.fn.fn_animate = function($menu_id,$direction,$val) {
                let $el = $(this),
                    $animation_delay = $el.parent().find("[data-menu=\""+$menu_id+"\"]").data("menu-delay") + $animation_duration;

                let $anim_out, $anim_in;
                if ($direction === "in") {
                    $anim_out = "animate--out-left";
                    $anim_in = "animate--in-right"
                } else {
                    $anim_out = "animate--out-right";
                    $anim_in = "animate--in-left"
                }

                $el.closest(".elm_content").addClass("mod--animated");

                $el.addClass($anim_out).parent().find("[data-menu=\""+$menu_id+"\"]").addClass($anim_in);


                if ($nav_ssm.find("[data-menu-breadcrumbs]").children("li").length === 1) {
                    $nav_ssm.find("[data-submenu-back]").removeClass("mod--active");
                }

                $nav_ssm.animate({
                    scrollTop: 0
                }, $animation_delay);

                setTimeout(function(){
                    $el.removeClass($anim_out+" mod--active").parent().find("[data-menu=\""+$menu_id+"\"]").removeClass($anim_in).addClass("mod--active");
                    $el.closest(".elm_content").removeClass("mod--animated");
                    if ($direction === "in") {
                        $nav_ssm.find("[data-menu-breadcrumbs]").children("li").removeClass("mod--active").parent().append("<li class='mod--active' data-menu-id='"+$menu_id+"'>"+$val+"</li>").find("mod--active");
                    }

                    if ($nav_ssm.find("[data-menu-breadcrumbs]").children("li").length > 1) {
                        $nav_ssm.find("[data-submenu-back]").addClass("mod--active");
                    }
                }, $animation_delay);
            };

            $nav_ssm.find("[data-menu]").each(function() {
                let $el = $(this),
                    $el_length = $el.children("li").length - 1;

                $el.children("li").each(function(i){
                    $(this).css("animation-delay", i*$animation_delay+"ms");
                });

                $el.attr("data-menu-delay", $el_length * $animation_delay);
            });


            $nav_ssm.on("click", "[data-menu-breadcrumbs] [data-menu-id]", function() {
                let $el = $(this),
                    $menu_active = $nav_ssm.find("[data-menu].mod--active"),
                    $menu_id = $el.data("menu-id");

                if (!$el.hasClass("mod--active")) {
                    $el.addClass("mod--active").nextAll().remove();
                    $menu_active.fn_animate($menu_id, "out");
                }
            });


            $nav_ssm.on("click", "[data-submenu]", function(e){
                e.preventDefault();
                let $el = $(this),
                    $val = $el.prev().find("i").text(),
                    $menu_id = $el.data("submenu");

                $el.closest("[data-menu]").fn_animate($menu_id, "in", $val);
            });

            $nav_ssm.on("click", "[data-submenu-back]", function(e){
                e.preventDefault();
                let $menu = $nav_ssm.find("[data-menu].mod--active"),
                    $menu_prev = $("[data-menu-breadcrumbs] [data-menu-id].mod--active").prev(),
                    $menu_id = $menu_prev.data("menu-id");

                $menu_prev.addClass("mod--active").nextAll().remove();
                $menu.fn_animate($menu_id, "out");
            });
        }
    }
})(jQuery);