(function ($) {
    const $fn = $(".comp_ajurveda");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]");

        cssLoaded(function() {
            if($slider.children().length > 1) {
                $slider.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                    setGallerySize: false,
                    cellSelector: '.elm_item'
                })
            }

            $fn.on("mouseenter","[data-nav-item]",function () {
               $(this).trigger("click2");
            });

            $fn.on("click2","[data-nav-item]",function () {
                $slider.flickity( 'select', $(this).data("nav-item"), true, false);
            });

            if(win.width() > 960) {
                setTimeout(function () {
                    let nav_wrp = $fn.find(".wrp_comp_nav");
                    let nav = nav_wrp.find(".elm_svg");
                    nav.fadeIn(500,function () {
                        nav.find("[data-nav-item]").addClass("mod--ready");
                        setTimeout(function () {
                            if(navigator.userAgent.indexOf('Safari') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1){
                                nav_wrp.hide();
                                setTimeout(function () {
                                    nav_wrp.show();
                                },1);
                            }
                            nav.find("[data-nav-item]").first().trigger("click2");
                        },750)
                    })
                },500);
            }
        });

    }
})(jQuery);