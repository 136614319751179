(function ($) {
    let $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            $fn.each(function(){
                let $el = $(this),
                    $slider = $el.find("[data-slider]"),
                    $caption = $el.find("[data-slider-caption]"),
                    autoplay = $slider.data("slider");

                if($slider.children().length > 1) {
                    $slider.flickity({
                        contain: true,
                        pageDots: true,
                        prevNextButtons: false,
                        wrapAround: true,
                        autoPlay: autoplay,
                        setGallerySize: false,
                        pauseAutoPlayOnHover: false,
                        sync: $caption[0],
                    });
                    $caption.flickity({
                        contain: true,
                        wrapAround: true,
                        pageDots: false,
                        prevNextButtons: false,
                        adaptiveHeight: true,
                        pauseAutoPlayOnHover: false,
                    })
                }

                $el.on("click","[data-scroll]",()=>{
                    $el.nextAll("section, div").not(".comp_breadcrumbs").first().data("anchor-offset","#layout_header").anchor_anim(500);
                });
            })
        });
    }
})(jQuery);