let cdnjs = {
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=AIzaSyDQXe1Osa9EBAwqHwWLCwpOiXxykVdxQJI",
    "masonry": "https://cdnjs.cloudflare.com/ajax/libs/masonry/4.2.1/masonry.pkgd.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.12/js/lightgallery-all.min.js",
    "underscore": "https://cdnjs.cloudflare.com/ajax/libs/underscore.js/1.9.1/underscore-min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/2.0.1/cookieconsent.min.js",
    "froogaloop": "https://f.vimeocdn.com/js/froogaloop2.min.js"
};
