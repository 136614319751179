(function($) {
    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "" && $(this).find("input, textarea").length) {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });
        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });

        doc.on("click", "form [type=\"submit\"]",function(){
            let form = $(this).closest("form");
            form.find(".part_ui_input").each(function () {
                let el = $(this);
                if (!el.filter("[data-no-validation]").length && !el.hasClass("mod--invalid")) {
                    el.validation();
                }
            });
            if(form.find(".part_ui_input.mod--invalid").length){
                form.find(".part_ui_input.mod--invalid").first().find("input, textarea").get(0).scrollIntoView({block:"center"});
            } else {
                if(form.find("input:invalid, textarea:invalid").first().length) {
                    form.find("input:invalid, textarea:invalid").first().get(0).scrollIntoView({block:"center"});
                }
                
            }
        });
    }
})(jQuery);