(function ($) {
    const $fn = $(".comp_blog");

    if ($fn.length) {
        let $nav = $fn.find(".wrp_comp_nav"),
            top = $fn.find(".wrp_comp_body").position().top - $("#layout_header").outerHeight();

        let headroom = new Headroom($nav[0], {
            offset: top,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();

        win.on("resize",function () {
            if(win.width() < 960){
                top = $fn.find(".wrp_comp_body").position().top;
            }
            else {
                top = $fn.find(".wrp_comp_body").position().top - $("#layout_header").outerHeight();
            }

            headroom.offset = top;
        });

        let $nav_items = $nav.find(".elm_nav_item");

        $nav_items.on("click",function () {
            let $el = $(this);
            if($el.hasClass("mod--active")) return;

            $nav_items.filter(".mod--active").removeClass("mod--active");
            $el.addClass("mod--active");
        });
    }
})(jQuery);