(function ($) {
    const $fn = $(".comp_offers_categories_intro");

    if ($fn.length) {
        let $nav = $fn.find(".wrp_comp_nav_categories"),
            offset = $fn.position().top + $fn.outerHeight(true) - $nav.outerHeight(),
            headroom,
            $select = $fn.find("[data-nav-select]");

        $("#layout_header").addClass("mod--categories");

        $fn.on("change",$select,function () {
            window.location.href = $nav.find("a").eq($select[0].selectedIndex).attr("href");
        });

        let visual = doc.find(".comp_visual, .comp_visual_button, .comp_visual_text, .comp_visual_image").first();
        if(visual.length) {
            let logo = visual.find(".wrp_comp_logo");
            if(logo.length){
                offset = visual.position().top + logo.position().top + logo.outerHeight();
            }

            win.on("resize",function () {
                if(win.width() < 960){
                    offset = visual.position().top + logo.position().top + logo.outerHeight();
                }
                else {
                    offset = offset = visual.position().top + logo.position().top + logo.outerHeight(); - $("#layout_header").outerHeight();
                }
                headroom.offset = offset;
            });
        }

        headroom = new Headroom($nav[0], {
            offset: offset,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    }
})(jQuery);