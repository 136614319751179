(function ($) {
    let $fn = $(".comp_visual_button");

    if ($fn.length) {
        cssLoaded(function() {
            $fn.each(function(){
                let $el = $(this);
                $el.on("click","[data-scroll]",()=>{
                    $el.nextAll("section, div").not(".comp_breadcrumbs").first().data("anchor-offset","#layout_header").anchor_anim(500);
                });
            })
        });

        $fn.find('a[href^="#"]').each(function () {
            $(this).attr("data-anchor",true);
        })
    }
})(jQuery);
