(function ($) {
    let $fn = $(".comp_history");

    if ($fn.length) {
        let $nav = $fn.find("[data-nav]");
        let $slider = $fn.find("[data-slider]");
        let wait = 0;

        cssLoaded(function() {
            if(win.width() > 960) {
                html.addClass("mod--no-scroll");
            }
            win.on("resize",function () {
                if(win.width() > 960) {
                    html.addClass("mod--no-scroll");
                }
                else {
                    html.removeClass("mod--no-scroll");
                }
            });
            if ($slider.children().length > 1) {
                $slider.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                    setGallerySize: false,
                    cellSelector: '.elm_item'
                });

                let flkty = $slider.data("flickity");

                $slider.on( 'settle.flickity', function( event, index ) {
                    setTimeout(function () {
                        wait = 0;
                        if(flkty.selectedIndex !== $fn.find("[data-nav-item]").length -1) {
                            $fn.find(".wrp_comp_background").find(".elm_icon").stop().fadeIn();
                        }
                    },1000);
                });
            }

            $fn.on("click", "[data-nav-item]", function () {
                $slider.flickity('select', $(this).index(), true, false);
            });
        });

        function slides(event){
            if(win.width() > 959) {
                event.preventDefault();
                if(event.originalEvent.deltaY > 0 || event.key === "ArrowDown") {
                    $nav.find("li").filter(".mod--active").next("li").trigger("click");
                }
                if(event.originalEvent.deltaY < 0 || event.key === "ArrowUp") {
                    $nav.find("li").filter(".mod--active").prev("li").trigger("click");
                }
            }
        }

        $.getScript(cdnjs.underscore).done(function () {
            win.on("wheel",_.throttle(function (e) {
                if(!$(e.target).closest(".wrp_comp_nav").length) {
                    if(wait === 0) {
                        $fn.find(".wrp_comp_background").find(".elm_icon").stop().fadeOut();
                        wait = 1;
                        slides(e);
                    }
                }
            },1750));
            win.on("keyup",function (e) {
                slides(e);
            });
        });


        $fn.on("click","[data-nav-item]",function (e) {
            e.stopPropagation();
            let $elm = $(this);

            $nav.animate({
                scrollTop: $elm.position().top - $elm.outerHeight(true)
            });

            $fn.find("[data-arrow]").removeClass("mod--disabled");

            if ($elm.index() === $nav.find("li").length - 1){
                $fn.find("[data-arrow=\"next\"]").addClass("mod--disabled");
            }

            if ($elm.index() === 0){
                $fn.find("[data-arrow=\"prev\"]").addClass("mod--disabled");
            }
        });

        $fn.on("click","[data-arrow]",function () {
            let $elm = $(this);

            if($elm.data("arrow") === "prev") {
                $nav.find("li").filter(".mod--active").prev("li").trigger("click");
            }
            else {
                $nav.find("li").filter(".mod--active").next("li").trigger("click");
            }
        })
    }
})(jQuery);