(function ($) {
    const $fn = $(".comp_gallery");

    if ($fn.length) {

        let $nav = $fn.find(".wrp_comp_nav"),
            top = $fn.position().top - $("#layout_header").outerHeight(),
            $select = $fn.find("[data-nav-select]");

        let headroom = new Headroom($nav[0], {
            offset: top,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            },
            onNotTop: function () {
                $("#layout_header").addClass("mod--categories");
            },
            onTop: function () {
                $("#layout_header").removeClass("mod--categories");
            }
        });
        headroom.init();

        win.on("resize",function () {
            if(win.width() < 960){
                top = $fn.position().top;
            }
            else {
                top = $fn.position().top - $("#layout_header").outerHeight();
            }
            headroom.offset = top;
        });


        $fn.on("change",$select,function () {
            $nav.find("a").eq($select[0].selectedIndex).click();
        });
    }
})(jQuery);